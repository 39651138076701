hr{
    border-color: #c9c9c9;
}
.mh46{
    min-height: 46px;
    border-color: #e5e7eb;
    box-sizing: border-box;
}
body{
    background: #fff;
    overflow-y: auto;
}
.image-preview-container{
    position: relative;
}
.tab-profile li button.active{
    border-bottom: 2px solid #EC2028;
}
.tab-profile li {
    min-width: 20%;
    text-align: center;
}
.tab-profile{
    border-bottom: 0.5px solid #000000;
}
.btn-edit{
    right: 90px;
    top: 152px;
    position: absolute;
    margin-left: auto;
}
.menu .active{
    background-color: #FDDBDB;
    color: #EC2028;
}
.menu .active img{
    filter: invert(21%) sepia(74%) saturate(3357%) hue-rotate(344deg) brightness(91%) contrast(103%);
}
nav.bg-menu-bg, nav #navbar-multi-level ul{
    background-color: #ffffff;
}
nav, nav #navbar-multi-level ul li a, nav #navbar-multi-level ul li button{
    color: #000;
}
nav #navbar-multi-level ul li div.origin-top-right.absolute .bg-white a{
    color: #000;
}
.menu-mobile li{
        list-style-type: none;
}
.btn-profile{
    min-height: 40px;
    align-items: center;
    min-width: 100px;
    justify-content: center;
    display: flex;
}
.style-input {
  background: transparent;
  border-bottom: 1px solid #fff;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}
.bg-login {
  background: transparent linear-gradient(209deg, #d7d6d6 0%, #545454 100%) 0%
    0% no-repeat padding-box !important;
}
.box-login {
  background: transparent linear-gradient(148deg, #656565 0%, #262626 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 6px 10px #404040;
  opacity: 1;
}
.btn-login ,a.btn-login{
  background: #8b8b8b 0% 0% no-repeat padding-box;
  height: 57px;
}
.bg-menu {
  background: transparent linear-gradient(270deg, #dbdbdb 0%, #b1b0b0 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
